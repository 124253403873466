<template>
  <FeedActivityList :selectedFund="selectedFund" :notesOnly="notesOnly" templateForComponent="Security Mentioned Notes" ref="activityList" />
</template>

<script>
import FeedActivityList from '../feed/FeedActivityList.vue';

import { mapState } from 'vuex';
import EventBus from '../../event-bus';
export default {

    components: {
        FeedActivityList
    },

    data() {
      return {
        notesOnly: false,
        justMounted: false,
        currentSymbol: null,
      }
    },
    
    created() {
      EventBus.on('toggle-notes-only-button', () => {
            this.notesOnly = !this.notesOnly;
      });
    },

    computed: {
      ...mapState(['selectedFund']),

      selectedSymbol() {
        return this.selectedFund.symbol;
      }
    },

    mounted() {
      this.justMounted = true;
    },

    activated() {
      if( !this.justMounted){
        if( this.currentSymbol != this.selectedSymbol) {
          this.currentSymbol = this.selectedSymbol;
          this.$refs.activityList.refreshFeed();
        }
      }
      else {
        this.justMounted = false;
      }
    },
}
</script>

<style>

</style>